
/**
 * @name: 出库记录
 * @author: itmobai
 * @date: 2023-07-24 11:40
 * @description：出库记录
 * @update: 2023-07-24 11:40
 */
import { Vue, Component } from "vue-property-decorator"
import { ICrudOption } from "@/types/m-ui-crud";
import { mallCheckoutQueryApi, mallCheckoutExportCheckOutApi } from "@/apis/site/checkOut"
import { mallSiteQueryByListApi } from "@/apis/site/list"
import { ICheckOut, ICheckOutParam } from "@/apis/site/checkOut/types"
import config from "@/config";
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class checkOutPage extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格数据
  tableData: ICheckOut[] = []
  // 表格数据
  tableTotal: number = 0
  // 查询参数
  queryForm: ICheckOutParam = {
    page: 1,
    limit: 10
  }
  totalCheckOut = 0
  // 表单数据
  modelForm: Partial<ICheckOut> = {}
  // 配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "出库记录ID",
        prop: "id",
        align: "left",
        width: 180,
        search: true
      },
      {
        label: "商品名称",
        prop: "goodsName",
        align: "center",
        search: true,
        placeholder: "输入商品名称模糊搜索"
      },
      {
        label: "商品属性",
        prop: "goodsTypes",
        align: "center",
        search: true,
        type: "select",
        dicData: [
          {
            label: "单品",
            value: 1
          },
          {
            label: "套餐",
            value: 2
          }
        ]
      },
      {
        label: "商品分类",
        prop: 'productSortId',
        hide: true,
        search: true,
        placeholder: "请选择商品分类",
        type: "select",
        dicUrl: '/api/admin/productSort/dropDown',
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'id'}),
      },
      {
        label: "商品分类",
        prop: 'sortName',
        align: "center",
      },
      {
        label: "站点",
        prop: "siteName",
        align: "center",
        overHidden: true,
      },
      {
        label: "站点",
        prop: "siteId",
        search: true,
        type: "select",
        dicData: [],
        hide: true,
      },
      {
        label: "出库数量(份)",
        prop: "checkoutQty",
        align: "center",
        width: 100,
      },
      {
        label: "出库类型",
        prop: "checkoutTypes",
        type: "select",
        search: true,
        dicData: [
          {
            label: "销售出库",
            value: 1
          },
          {
            label: "采购撤单",
            value: 2
          }
        ]
      },
      {
        label: "关联单号",
        prop: "orderSn",
        search: true,
        align: "center",
        overHidden: true
      },
      {
        label: "出库时间",
        prop: "checkoutTime",
        search: true,
        type: "daterange",
        align: "center",
        width: 180
      }
    ]
  }

  getList () {
    this.tableLoading = true
    const form: ICheckOutParam = deepCopy(this.queryForm);
    if (this.queryForm.checkoutTime && this.queryForm.checkoutTime.length) {
      form.addStartTime = this.queryForm.checkoutTime[0]
      form.addEndTime = this.queryForm.checkoutTime[1]
    } else {
      form.addStartTime = ""
      form.addEndTime = ""
    }
    delete form.checkoutTime
    mallCheckoutQueryApi(form).then(e => {
      this.totalCheckOut = e.total;
      this.tableTotal = e.checkoutVoCommonPage.total
      this.tableData = e.checkoutVoCommonPage.list
      this.tableLoading = false
    })
  }

  getSiteSelect () {
    mallSiteQueryByListApi().then(e => {
      // @ts-ignore
      this.$refs.crudRef.updateSearchColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
      // @ts-ignore
      this.$refs.crudRef.updateFormColumn("siteId", {
        dicData: e.map(item => {
          return {
            label: item.siteName,
            value: item.id
          }
        })
      })
    })
  }

  /**
   * 导出excel
   */
  exportExcel () {
    const form: ICheckOutParam = deepCopy(this.queryForm);
    if (this.queryForm.checkoutTime && this.queryForm.checkoutTime.length) {
      form.addStartTime = this.queryForm.checkoutTime[0]
      form.addEndTime = this.queryForm.checkoutTime[1]
    } else {
      form.addStartTime = ""
      form.addEndTime = ""
    }
    delete form.checkoutTime
    mallCheckoutExportCheckOutApi(form).then(e => {
      exportFile(e, '出库记录.xlsx')
    })
  }

  created () {
    this.getList()
    this.getSiteSelect()
  }
}
