/**
 * @name: 出库记录接口
 * @author: itmobai
 * @date: 2023-07-24 11:42
 * @description：出库记录接口
 * @update: 2023-07-24 11:42
 */

import { get, postW } from "@/request";
import { ICheckOut, ICheckOutParam } from "./types";
import { IPageRes } from "@/apis/page";

export const mallCheckoutQueryApi = (query: ICheckOutParam) => get("/mall/checkout/query", query)

export const mallCheckoutExportCheckOutApi = (data: ICheckOutParam) => postW("/mall/checkout/exportCheckout", data, "blob")
